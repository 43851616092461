import React from "react"
import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const GuitarFinishing = () => {

  return (
    <PageLayout>
      <SEO
        title="Guitar Finishing | Your Custom Build | King Electric Guitars"
        ogDescription="Guitar finishing is my favorite part of building custom guitars. The options are limitless: painted options, sunbursts, natural wood finish, and fabric tops. Let's build your dream guitar!"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Guitar Finishing</h1>
            <p>
              There are tons of options for how we finish your custom guitar. There's all kinds of painted options, sunbursts, natural wood finish, and fabric tops.
            </p>
            <p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Reach Out. Let's Talk!",
                size: "tiny",
                icon: "chat",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/custom-guitar-finish-fabric-tops-unfinished.jpg" alt="Guitar Finishing" />
          </div>
        }
      />
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/custom-guitar-finish-fabric-top-paisley.jpg" alt="Guitar Finishing Fabric Top" />
            </div>
          }
          textRight={
            <div>
              <h2>Fabric Top Guitar Finish</h2>
              <p>
                I'm big into the 1960's and 1970's paisley designs. The first several guitars I did were fabric tops. Each has different paisleys patterns and colors. It's a look that makes a huge impact and brings a lot of style to the guitar. I especially love using it on my T-Style model. With a high gloss finish, they look beautiful.
              </p>
              <p>
                Not every kind of fabric works well. <a href="https://www.fabric.com/find?fabric-pattern=paisley&fiber-content-range=100percent-cotton" target="_blank" rel="noreferrer">Cotton and cotton blends</a> tend to work best. The color will get darker when I apply the finish coats. But pretty much any fabric store on the planet will have a huge selection of suitable fabric options. I keep a decent selection here at the shop and I'm always on the lookout for more. But you can also supply your own fabric for your custom guitar if you find something you love.
              </p>
            </div>
          }
        />
      </div>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Guitar Body Finish Grade</h2>
            <p>
              The type of wood we choose can have an effect on the final finish as well. If you want to see the wood grain through your paint, then we can choose heavier-grained woods with interesting grain patterns. If you prefer a smooth finish, then we can use grain filling techniques which will achieve a glass finish.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/custom-guitar-finish-purple-stain.jpg" alt="Guitar Finishing Grade" />
          </div>
        }
      />
      <PageWrapper color="grey">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/custom-guitar-finish-natural-finish.jpg" alt="Natural Wood Guitar Finish" />
            </div>
          }
          textRight={
            <div>
              <h2>Natural Wood Guitar Finish</h2>
              <p>
                If you're going for a natural wood look, there are a lot of options. We can combine contrasting woods, veneer, and wood bindings to create a unique and beautiful piece. Pictured is the Ethermaster, which is a Jazzmaster-style body made of Sapele and Zebrawood. After sanding, I used a TRU-OIL gun stock finish. It brings out the depth of the Sapele's natural stripes. I then glued the center block with alternating Zebrawood and Sapele stripes. This takes style cues from the aluminum neck Kramer's of the 70's. The TRU-OIL maintains the natural wood tones while producing a rich, satin finish. I can achieve similar effects with danish oil and tung oil finishes, which can incorporate a stain to alter the wood's tone.
              </p>
              <FormWrapper
                modal
                buttonProps={{
                  content: "Reach Out. Let's Talk!",
                  size: "tiny",
                  icon: "chat",
                }}
              >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
          }
        />
      </PageWrapper>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Car-Inspired Guitar Paint</h2>
            <p>
              I come from a custom car background. With custom cars, the best to me were the ones that you couldn't tell exactly what they did. You just knew that there was something different. And that's what I'm trying to do. I want to design guitars that look familiar, but it is different than what you can buy at the guitar megastore.
            </p>
            <p>
              Leo Fender's designs were also inspired by cars. He bought car paint from the same manufacturers that made paint for cars at that time. I catch myself daily driving around looking at cars for inspiration. I have asked so many people the year, make, and model of their car. Or, "Do you mind me looking at the paint code?" They think I'm insane. But I have gotten some cool colors off of Toyotas. And there's a cool green off of a Mini Cooper that I'm gonna spray a guitar soon. I have a little paint place in town that I can walk in and they'll make me enough paint to paint a couple of guitars. They think I'm crazy as well. But, I'm always in there looking for odd colors. I don't wanna paint things the same as everybody else. I always try to do things that are a little different.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/custom-guitar-finish-car-inspired.jpg" alt="Guitar Finishing Car Inspired Paints" />
          </div>
        }
      />
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/custom-guitar-finish-binding.jpg" alt="Guitar Finishing Edge Binding" />
            </div>
          }
          textRight={
            <div>
              <h2>Edge Binding</h2>
              <p>
                Edge binding can add a lot of character to your custom guitar finish. It works well as a transition between fabric or veneer tops to natural wood, stained, or painted backs. There are various colors of plastic binding, but there is also wood biding that I can use. It's also possible to paint or stain the binding a contrasting color ... even metallic.
              </p>
            </div>
          }
        />
      </div>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>Guitar Finish Coat</h2>
            <p>
              I spray most of my guitars with a <a href="https://blog.andertons.co.uk/labs/nitro-vs-poly-guitar-finishes" target="_blank" rel="noreferrer">urethane finish</a>. A lot of builders are going that way. If you demand nitrocellulose lacquer, I can do that. But, the price will be higher and the turn-around will be much slower. Nitrocellulose takes a lot longer to cure and creates a lot more labor. Also, keep in mind that nitrocellulose will yellow with time. This will alter the guitar's original look. A urethane finish will pretty much always look the same.
            </p>
            <h3>What Would Leo Do?</h3>
            <p>
              If Leo Fender was alive today, he would be using urethane finishes too. If you disagree, you're kidding yourself. He used anything he could to speed up the process and be able to source items affordable. I can buy a quart of nitrocellulose for $40 or I can buy two gallons of urethane for $40 dollars. I know what Leo would pick today.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/custom-guitar-finish-paint-gun.jpg" alt="Guitar Finish Coat" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>Open to Crazy Ideas</h3>
            <p>
              If you have some crazy idea for a finish you want to try, then I'm game to give it a shot. To me, that's the whole point of a custom guitar. Let's get crazy. Let me know what you're thinking.
            </p>
            <h4>Subltle Relic Finishes</h4>
            <p>
              I'm not a fan of heavily relic'ed guitars. It's not my thing. But, if the wood selection and overall aesthetic call for it, I'm down for it. For instance, my <a href="/guitar006-prototype-two/">Prototype 2 guitar</a> was made of cyprus and polar, soft woods that are going to get dinged. So, it made sense to gently relic it in places. Tell me what you want. Let's talk it out.
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Get in touch. Let's talk about building your dream guitar!</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default GuitarFinishing
